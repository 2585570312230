<template>
  <wizard-form v-on="$listeners" v-bind="$attrs">
    <template #content>
      <v-row dense>
        <v-col cols="12">
          <div>
            <h1 class="primary--text  font-weight-bold">
              {{ $t('transfer_internal_account') }}
            </h1>
            <div class="mt-2 mb-4">
              <hr class="rounded primary" color="primary"/>
              <hr class="primary" size="1" color="primary" />
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_alias')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_alias')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.alias"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_account_code')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              :label="`${$t('transfer_account_code')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.bnfBankAccNumber"
              :error-messages="errors[0]"
              maxlength="50"
            />
          </ValidationProvider>
        </v-col>
        <v-col v-if="!isAccountCode && isExecutive" cols="12" md="6">
          <ValidationProvider
            :name="$t('transfer_name')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-select
              :label="`${$t('transfer_name')} *`"
              v-model="permanentInstruction.beneficiary"
              :items="beneficiaries"
              dense
              autocomplete="nope"
              :error-messages="errors[0]"
            />
          </ValidationProvider>
        </v-col>
        <v-col
          v-if="isAccountCode || !isExecutive"
          :cols="12"
          :md="6"
          class="d-flex flex-grow-1"
        >
          <ValidationProvider
            class="flex-grow-1"
            :name="$t('transfer_name')"
            rules="required|max:50"
            v-slot="{ errors }"
          >
            <v-text-field
              class="flex-grow-1"
              ref="beneficiary"
              :label="`${$t('transfer_name')} *`"
              dense
              autocomplete="nope"
              v-model="permanentInstruction.beneficiary"
              :error-messages="errors[0]"
              :disabled="lockedField"
              maxlength="50"
            />
          </ValidationProvider>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                v-if="lockedField"
                icon
                v-on="on"
                @click="handleUnlock"
                :disabled="!lockedField"
              >
                <v-icon>mdi-lock-open-variant</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('modify_beneficiary') }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_observations')"
            dense
            autocomplete="nope"
            v-model="permanentInstruction.bnfReference"
            maxlength="128"
          />
        </v-col>
        <v-col cols="12">
          <v-text-field
            :label="$t('transfer_instructions')"
            dense
            autocomplete="nope"
            v-model="permanentInstruction.avsInternalRemark"
            maxlength="128"
          />
        </v-col>
      </v-row>
    </template> 
  </wizard-form>
</template>
<script>
import WizardForm from '@/components/commons/WizardForm'
import { mapState } from 'vuex'
export default {
  components: {
    WizardForm
  },
  props: {
    permanentInstruction: {
      type: [Object]
    }
  },
  data() {
    return {
      lockedField: true,
      stepTwo: {
        account_type: 'Cuentas de Bancos en Panamá'
      },
      items: [
        'Cuenta Corriente en Banco',
        'Cuenta Ahorro en Banco',
        'Cuenta Inversión en Institución Financiera no Banco'
      ],
      banks: ['Banco Mercantil', 'Banesco'],
      isThirdParty: false
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('account', ['account', 'accountData']),
    isExecutive() {
      return this.user?.authorityLevelsCode === 1
    },
    isAccountCode() {
      return (
        this.user?.avobjects?.findIndex(
          item =>
            item.code === this.account.code && item.typeCode === 'accountCode'
        ) > -1
      )
    },
    beneficiaries() {
      let items = []
      const main = this.accountData.firstname + ' ' + this.accountData.lastname
      items.push(main)

      if (this.accountData.type.toUpperCase() === 'JURIDICA') {
        const beneficiaries = this.accountData.beneficiaries
          .map(item => item.firstname + ' ' + item.lastname)
          .filter(item => item.trim() !== '')

        items = items.concat(beneficiaries)
      } else if (
        this.accountData.coOwners &&
        this.accountData.coOwners.trim() !== ''
      ) {
        items.push(this.accountData.coOwners)
      }

      return items
    }
  },
  methods: {
    async handleUnlock() {
      const text =
        this.$t('warning_instructions_payments_to_third_parties_1') +
        ' ' +
        this.$t('warning_instructions_payments_to_third_parties_2')

      const params = {
        text,
        title: this.$t('twofactor_modal_warning_title'),
        showClose: false,
        persistent: true,
        actions: {
          true: {
            text: this.$t('btn_accept'),
            color: 'primary'
          }
        }
      }

      await this.$dialog.info(params)
      this.isThirdParty = true
      this.lockedField = false
      this.permanentInstruction.beneficiary = ''
      this.permanentInstruction.bnfEmail = ''
      this.permanentInstruction.bnfEmailConfirm = ''
      this.permanentInstruction.isThirdParty = this.isThirdParty
      // Set focus
      this.$nextTick(() => this.$refs.beneficiary.focus())
    }
  }
}
</script>
<style scoped>
.main-container {
  line-height: normal;
}

hr {
  margin: 0;
  padding: 0;
}

hr.rounded {
  border-top: 2px solid;
  width: 400px;
}
</style>